import { mainRequests } from "./custom";
import { getGroups, getToken } from "../utils/auth";
import axios from "axios";

export default {
  getAvailableOutlet() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      mainRequests
        .get("/collection_fee_config/getAvailableOutlet", requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCollectionFeeConfig() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      mainRequests
        .get("/collection_fee_config", requestOptions)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createCollectionFeeConfig(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      mainRequests
        .post("/collection_fee_config", payload, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateCollectionFeeConfig(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      mainRequests
        .put(`/collection_fee_config/${id}`, payload, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteCollectionFeeConfig(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      mainRequests
        .del(`/collection_fee_config/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getServices(deleted, status) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}service?deleted=${deleted}&status=${status}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getLatePayStepById(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}latePayStep/${id}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createLatePayStep(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          process.env.VUE_APP_ADMIN_API + "latePayStep",
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateLatePayStep(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          process.env.VUE_APP_ADMIN_API + "latePayStep/" + id,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteLatePayStep(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete(
          process.env.VUE_APP_ADMIN_API + "latePayStep/" + id,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getLatePaySteps() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      // getGroups().then(data => {
      // let role =
      //   (data.indexOf("boss") || data.indexOf("admin")) > -1
      //     ? "admin"
      //     : data[0]
      //     ? data[0]
      //     : "";
      // payload.role = role;
      axios
        .get(process.env.VUE_APP_ADMIN_API + "latePayStep", requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOutlets(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      getGroups().then(data => {
        let role =
          (data.indexOf("boss") || data.indexOf("admin")) > -1
            ? "admin"
            : data[0]
            ? data[0]
            : "";
        payload.role = role;
        axios
          .post(
            process.env.VUE_APP_ADMIN_API + "outlet",
            payload,
            requestOptions
          )
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    });
  },
  deletePartner(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}outlet/${id}/deletePartner`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  restorePartner(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}outlet/${id}/restorePartner`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOutletById(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}outlet/${id}`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getMainCategories(serviceList) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}category/getMainCategoryList` +
            "?serviceList=" +
            serviceList,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getPartnersForGrocerySort() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}outlet/getPartnersForGrocerySort`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updatePartnersGroceryDeliveryType(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}outlet/updatePartnersGroceryDeliveryType`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getMenuCategories(service, search, type) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}category/getMenuCategoryList` +
            "?service=" +
            service +
            "&search=" +
            search +
            "&type=" +
            type,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  downloadOutlets() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}outlet/downloadOutletInfo`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  dashboard(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}outlet/dashboard`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNewPartners(outletName, email) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}outlet/newPartners` +
            "?outletName=" +
            outletName +
            "&email=" +
            email,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNoSalesPartner(size, from, outletName) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}outlet/noSalesPartners` +
            "?size=" +
            size +
            "&from=" +
            from +
            "&outletName=" +
            outletName,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  editPartner(outletId, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}outlet/${outletId}/info`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCognitoUserList(cognitoName) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}other/cognito/users/${cognitoName}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  editPartnerfinanceInfo(outletId, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}outlet/${outletId}/financeInfo`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  editPartnerContractInfo(outletId, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}outlet/${outletId}/contractInfo`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  editPartnerSchedule(outletId, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}outlet/${outletId}/timeSchedule`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllBanners(size, from, status, search) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}banner?size=${size}&from=${from}&status=${status}&search=${search}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateBanner(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}banner/${id}`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createBanner(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(`${process.env.VUE_APP_ADMIN_API}banner`, payload, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  uploadBannerImage(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}banner/uploadBannerImage`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getTimeLimitNamesBanner() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}banner/getTimeLimitNames`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getBadges() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(`${process.env.VUE_APP_ADMIN_API}badge`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateBadge(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}badge/${id}`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createBadge(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(`${process.env.VUE_APP_ADMIN_API}badge`, payload, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteBadge(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete(`${process.env.VUE_APP_ADMIN_API}badge/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteBanner(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete(`${process.env.VUE_APP_ADMIN_API}banner/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getSearchCategories() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}other/getAllSearchCategory`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllSuggestMenus(service, is_show) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}suggestMenu?service=${service}&is_show=${is_show}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getActiveSuggestMenus(is_show) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}suggestMenu?is_show=${is_show}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createSuggestMenu(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}suggestMenu`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateSuggestMenu(payload, id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}suggestMenu/${id}`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteSuggestMenu(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete(
          `${process.env.VUE_APP_ADMIN_API}suggestMenu/${id}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createService(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}service`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateService(payload, id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}service/${id}`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteService(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete(`${process.env.VUE_APP_ADMIN_API}service/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getMenusByOutlet(outletId, is_draft, is_active) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}product?outletId=${outletId}&is_draft=${is_draft}&is_active=${is_active}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteMenu(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete(`${process.env.VUE_APP_ADMIN_API}product/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  restoreMenu(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(`${process.env.VUE_APP_ADMIN_API}product/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  productDashboard(outletId) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}product/${outletId}/dashboard`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllLoan(phone, loan_status, size, from) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}loan?phone=${phone}&loan_status=${loan_status}&size=${size}&from=${from}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  loanUpdate(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}loan/${id}`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getMenuById(productId) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}product/${productId}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getRestLoggs(outletId, size, from) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}outlet/logs?outletId=${outletId}&size=${size}&from=${from}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllBags() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(`${process.env.VUE_APP_ADMIN_API}bag`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOneBag(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(`${process.env.VUE_APP_ADMIN_API}bag/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateBag(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}bag/${id}`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createBag(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(`${process.env.VUE_APP_ADMIN_API}bag`, payload, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteBag(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete(`${process.env.VUE_APP_ADMIN_API}bag/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllSortTypes() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(`${process.env.VUE_APP_ADMIN_API}menuSort`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOneSortType(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(`${process.env.VUE_APP_ADMIN_API}menuSort/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateSortType(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}menuSort/${id}`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createSortType(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}menuSort`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteSortType(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete(
          `${process.env.VUE_APP_ADMIN_API}menuSort/${id}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOutletNames(service) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}outlet/getOutletNames?service=${service}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getProductPropertiesByService(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}other/getProductPropertiesByService`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getMenuLog(size, from, productId) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}product/logs?size=${size}&from=${from}&productId=${productId}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOwnerList() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(`${process.env.VUE_APP_ADMIN_API}other/ownerList`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  kitchenLogin(outletId) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}outlet/${outletId}/kitchenLogin`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  confirmNewPartner(outletId, data) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}outlet/${outletId}/newPartner`,
          data,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteNewPartner(outletId) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete(
          `${process.env.VUE_APP_ADMIN_API}outlet/${outletId}/newPartner`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getHoroos(city, district) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}address/horoos?city=${city}&district=${district}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCities() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(`${process.env.VUE_APP_ADMIN_API}address/cities`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getDistricts(city) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}address/districts?city=${city}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllAddress(size, from, search, is_active, district) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}address?size=${size}&from=${from}&is_active=${is_active}&search=${search}&district=${district}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAddressLogs(size, from) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}address/logs?size=${size}&from=${from}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOneLog(size, from, id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}address/${id}/log?size=${size}&from=${from}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateAddress(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}address/${id}`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateAddresLoc(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}address/location/${id}`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createAddress(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}address`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOrdersDashboard(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}other/getOrdersDashboard?start_date=${payload.start_date}&end_date=${payload.end_date}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  downloadOrdersDashboard(start_date, end_date, type) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}other/downloadOrdersDashboard?start_date=${start_date}&end_date=${end_date}&type=${type}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async getDeliveryChargeConfigs(type) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}deliveryCharge?type=${type}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async updateDeliveryChargeConfigs(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}deliveryCharge/${id}`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async createDeliveryChargeConfigs(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}deliveryCharge`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async deleteDeliveryChargeConfigs(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete(
          `${process.env.VUE_APP_ADMIN_API}deliveryCharge/${id}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async getAllPickHour() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          process.env.VUE_APP_ADMIN_API + "pickHourConfig/getAllPickHour",
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async createPickHour(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          process.env.VUE_APP_ADMIN_API + "pickHourConfig/",
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async updatePickHour(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          process.env.VUE_APP_ADMIN_API + "pickHourConfig/" + id,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async deletePickHour(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete(
          process.env.VUE_APP_ADMIN_API + "pickHourConfig/" + id,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateOrCreateOrderLogin(outletId, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}outlet/${outletId}/kitchenLoginChange`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createNewPartnerLogin(outletId, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}outlet/${outletId}/kitchenLogin`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updatePartnerLogin(outletId, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}outlet/${outletId}/kitchenLogin`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // ******MALL*********************
  getMallDashboard(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          "https://api-v1.toktokmall.mn/api/getOrdersDashboard" +
            "?start_date=" +
            payload.start_date +
            "&end_date=" +
            payload.end_date,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllCategories() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .customGet(
          `https://api-v1.toktokmall.mn/api/v1/getAllCategories?channel=WEB`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getMallProduct(payload) {
    return new Promise(function(resolve, reject) {
      axios
        .post("https://api-v1.toktokmall.mn/api/v1/search", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getMallBrands(character, search, size, from) {
    return new Promise(function(resolve, reject) {
      axios
        .post(
          `https://api-v1.toktokmall.mn/api/v1/getAllBrands?first_character=${character}&brand_name=${search}&size=${size}&from=${from}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOneProductMall(id) {
    return new Promise(function(resolve, reject) {
      axios
        .get(
          `https://api-v1.toktokmall.mn/api/v1/getOneProduct?product_id=${id}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOneBrandMall(id) {
    return new Promise(function(resolve, reject) {
      axios
        .get(`https://api-v1.toktokmall.mn/api/v1/getOneBrand?brand_id=${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getPods(brand, outlet, podcode) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .getPodAddress(
          "/getPods?brand=" +
            brand +
            "&outlet=" +
            outlet +
            "&podcode=" +
            podcode
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAddress(address, district, horoo) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .getAddress(
          "/search?district=" +
            district +
            "&address=" +
            address +
            "&horoo=" +
            horoo
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAddressByPod(address, podcode) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .getPodAddress(
          "/getAddressByPod?address=" + address + "&podcode=" + podcode
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOutletsByPods(brand) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .getPodAddress("/getOutletsFromPods?brand=" + brand)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAddressNearby(lat, lon) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .getAddress("/getAddressNearby?lat=" + lat + "&lng=" + lon)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllTpr(size, from, search, status) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `https://rest.toktok.mn/admin/tprConfig?size=${size}&from=${from}&search_text=${search}&status=${status}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getTprReport(minTpr, maxTpr, startDate, endDate) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `https://rest.toktok.mn/admin/tprReport?tpr_min=${minTpr}&tpr_max=${maxTpr}&start_date=${startDate}&end_date=${endDate}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getTprHistory(size, from, search, startDate, endDate) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `https://rest.toktok.mn/admin/tprConfig/logs?size=${size}&from=${from}&search_text=${search}&start_date=${startDate}&end_date=${endDate}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getTprDeliveryPrice() {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(`https://rest.toktok.mn/admin/tprDeliveryPrice`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getTprDeliveryPriceDetail(hexaName, isDefault) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `https://rest.toktok.mn/admin/tprDeliveryPrice?hexa_name=${hexaName}&is_default=${isDefault}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getTprDeliveryPriceHistory(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `https://rest.toktok.mn/admin/tprDeliveryPrice/${id}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteTprDeliveryPriceDetail(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete(
          `https://rest.toktok.mn/admin/tprDeliveryPrice/${id}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteTprDeliveryPriceList(body) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `https://rest.toktok.mn/admin/tprDeliveryPrice/delete`,
          body,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createTprDeliveryPriceDetail(body) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `https://rest.toktok.mn/admin/tprDeliveryPrice`,
          body,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateTprDeliveryPriceDetail(body) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `https://rest.toktok.mn/admin/tprDeliveryPrice/${body.id}`,
          body,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOneTpr(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(`https://rest.toktok.mn/admin/tprConfig/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteTpr(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete(`https://rest.toktok.mn/admin/tprConfig/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createTpr(body) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(`https://rest.toktok.mn/admin/tprConfig`, body, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateTpr(body, id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `https://rest.toktok.mn/admin/tprConfig/${id}`,
          body,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateOutletHexagon(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}outlet/${payload._id}/deliveryZone`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  vatRegno(vatRegno) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .customGet("https://api.toktok.mn/v1/other/vatRegNo?reg_no=" + vatRegno)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllCategoriesByMenu() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/getAllCategoriesByMenu")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addPartner(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/addPartner", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  copyPartner(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/copyPartner", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addMenu(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/addMenu", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateMenu(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/updateMenu", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  uploadImage(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/uploadImage", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  uploadBanner(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/uploadAppBanner", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  uploadAppBannerNew(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/uploadAppBannerNew", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updatePolygonOnOutletById(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/updatePolygonById", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOutletTimetableById(id) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/getOutletTimetableById?id=" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllEditOutletRequests() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/editRequests/getEditOutletRequests")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getEditOutlet(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/editRequests/getEditOutlet", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNotificationLogs(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/notification/logList", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNotificationDetails(id) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/notification/detail?id=" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getDriverList() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .getDriver("/getUserList")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getDriverTeamList() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .getDriverStatus("admin/driver-group")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getDriversByStatus(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .getDriverStatus("admin/driver/getDriversByStatus", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getDrivers() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/driver/getDrivers")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getTotalStats(service) {
    return new Promise(function(resolve, reject) {
      getGroups().then(data => {
        let role =
          (data.indexOf("boss") || data.indexOf("admin")) > -1
            ? "admin"
            : data[0]
            ? data[0]
            : "";
        mainRequests
          .get("/getTotalStats?service=" + service + "&role=" + role)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    });
  },
  getDriveById(id) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .getDriver("/getUserByUid?user_uid=" + id)
        .then(res => {
          resolve(res.response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateDrive(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .postDriver("/updateUserByUid", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addDrive(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .postDriver("/createUser", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getStats(service) {
    return new Promise(function(resolve, reject) {
      getGroups().then(data => {
        // let role = data.indexOf("boss") > -1 ? "boss" : "admin";
        let role =
          (data.indexOf("boss") || data.indexOf("admin")) > -1
            ? "admin"
            : data[0]
            ? data[0]
            : "";
        mainRequests
          .get("/getStats?role=" + role + "?service=" + service)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    });
  },
  addCategory(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/createCategory", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateCategory(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/updateCategory", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteCategory(id) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/deleteCategory", id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createNotification1(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .customPost(
          "https://asia-northeast1-toktok-1df1f.cloudfunctions.net/notifications/createNotifications",
          payload
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getSpecialTexts() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/getAllSpecialTexts")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNotifications(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/notification/list", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNotifications1(state) {
    let finalstate = state ? state : 0;
    return new Promise(function(resolve, reject) {
      mainRequests
        .customGet(
          "https://asia-northeast1-toktok-1df1f.cloudfunctions.net/notifications/getNotifications?state=" +
            finalstate
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateDriverStatus(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .postDriver("/setDriverStatus", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNotificationListById(id) {
    // let finalstate = state ? state : 0
    return new Promise(function(resolve, reject) {
      mainRequests
        .customGet(
          "https://asia-northeast1-toktok-1df1f.cloudfunctions.net/notifications/getNotificationsById?id=" +
            id
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  manageDriverAccount(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .postDriver("/manageDriverAccount", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createNotification(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/notification/create", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  uploadAppSize(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/uploadAppSize", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  create(apart) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .postAddress("/createAddress", apart)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addSearchCategory(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/createSearchCategory", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateSearchCategory(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/updateSearchCategory ", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteSearchCategory(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/deleteSearchCategory ", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createMainCategory(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/createMainCategory", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteNotification(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/notification/delete", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateMainCategory(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/updateMainCategory ", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteMainCategory(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/deleteMainCategory", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOutletInfo(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/getOutletByOutletName", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createKitchenBanner(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/kitchen/createkitchenbanner", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getKitchenBanner() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/kitchen/getKitchenBanner")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  kitchenUploadBanner(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/kitchen/uploadBanner", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateKitchenBanner(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/kitchen/updatekitchenbanner", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteKitchenBanner(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/kitchen/deletekitchenbanner", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getoutletdevicebyoutletname(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/getoutletdevicebyoutletname", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateLoginNamePassword(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/updateLoginNamePassword", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteDriverTeam(body) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .deleteDriverTeam("admin/driver-group/" + body.id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createDriverTeam(body) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .postDriverTeam("admin/driver-group", body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateDriverGroup(body) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .patchDriverTeam("admin/driver-group/" + body.id, body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOutletHistory(outletData) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/getOutletOrderHistory?outlet=" + outletData)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOutletOrderDateRangeHistory(startDate, endDate) {
    let data = {
      start_date: startDate,
      end_date: endDate
    };
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/getOutletOrderDateRangeHistory", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getTimeLimitConfigs(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/getTimeLimitConfigs", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllMenuLimit() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/getAllMenuLimit")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createAppMenuTimeLimit(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/createAppMenuTimeLimit", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteAppMenuTimeLimit(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/deleteAppMenuTimeLimit", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateAppMenuTimeLimit(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/updateAppMenuTimeLimit", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  editOutletRequests(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/editedOutletRequests", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  editOutletRequest(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/editedOutletRequest", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  approveOutletEditedRequest(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/approveOutletEditedRequest", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getEditMenuRequestsByOutlet(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/getEditMenuRequestsByOutlet", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getEditMenuRequests() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/getEditMenuRequests")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getChartData() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/getChartData")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getChartData1() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/getChartData1")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getEditMenuRequest(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/getEditMenuRequest", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNewMenuRequests() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/getNewMenuRequests")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNewMenuRequest(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/getNewMenuRequest", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  approveMenuEditRequest(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/approveMenuEditRequest", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getSpecialMenus() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/getSpecialMenus")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateAppSuggestType(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/updateAppSuggestType", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createAppSuggestType(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/createAppSuggestType", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getAllActiveTodayMenu() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/MenuLimitTime/getAllActiveTodayMenu")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getStatsWithService(service) {
    return new Promise(function(resolve, reject) {
      getGroups().then(data => {
        let role =
          (data.indexOf("boss") || data.indexOf("admin")) > -1
            ? "admin"
            : data[0]
            ? data[0]
            : "";
        mainRequests
          .get("/getStats?service=" + service + "&role=" + role)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    });
  },
  getNewMenuRequestsByOutlet(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/getNewMenuRequestsByOutlet", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  approveNewMenuRequest(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/approveNewMenuRequest", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOutletAdditionalInfoHistory(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/getOutletAdditionalInfoHistory", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCategoriesPost(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/getCategories", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteAppSuggestType(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/deleteAppSuggestType", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  copyProduct(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/copyProduct", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteMenuCategory(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/menuCategory/deleteMenuCategory", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateMenuCategory(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/menuCategory/updateMenuCategory", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createMenuCategory(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/menuCategory/createMenuCategory", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllDriverConfig() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/driverConfig/getAllDriverConfig")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOneDriverConfig(body) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/driverConfig/getOneDriverConfig", body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateDriverConfig(body) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/driverConfig/updateDriverConfig", body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getLog(query, body) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/driverConfig/getLog" + query, body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNearbyPartnerLocation(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/getNearbyPartnerLocation", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteSpecialMenu(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/deleteSpecialMenu", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getProductProperties() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .get("/getProductProperties")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addProductProperty(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/addProductProperty", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateProductProperty(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/updateProductProperty", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteProductProperty(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/deleteProductProperty", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getTimeLimitConfigsforBanner(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/getTimeLimitConfigsForBanner", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createAppBannerTimeLimit(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/createAppBannerTimeLimit", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteAppBannerTimeLimit(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/deleteAppBannerTimeLimit", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateAppBannerTimeLimit(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/updateAppBannerTimeLimit", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getOutletsWorkingHours(payload) {
    return new Promise(function(resolve, reject) {
      mainRequests
        .post("/getOutletsWorkingHours", payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getBankCodes() {
    return new Promise(function(resolve, reject) {
      mainRequests
        .getDriverStatus("admin/driver/getBankCodes")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  Schedule(url, body) {
    return new Promise(function(resolve, reject) {
      axios
        .post("https://rest.toktok.mn/driver-api/" + url, body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async getAllStory(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}story?type=${payload.type}&status=${payload.status}&size=${payload.size}&from=${payload.from}`,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async getOneStory(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(`${process.env.VUE_APP_ADMIN_API}story/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async getUpdateStory(id, body) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          "https://rest.toktok.mn/admin-api/story/" + id,
          body,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async getDeleteStory(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .delete("https://rest.toktok.mn/admin-api/story/" + id, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async getPromotion(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}promotion`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async getOnePromotion(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(`${process.env.VUE_APP_ADMIN_API}promotion/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async updatePromotion(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}promotion/${id}`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async getAllIncentives(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}incentive/getAllIncentive`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async getDetailIncentives(id) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(`${process.env.VUE_APP_ADMIN_API}incentive/${id}`, requestOptions)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async addIncentives(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}incentive/`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async getPredictionByWeek(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_ADMIN_API}incentive/getPredictionByWeek`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async updateIncentives(id, payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .put(
          `${process.env.VUE_APP_ADMIN_API}incentive/${id}`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  downloadDriver(payload) {
    return new Promise(async function(resolve, reject) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken())
        }
      };
      axios
        .get(
          `${process.env.VUE_APP_ADMIN_API}other/downloadOrdersDashboard`,
          payload,
          requestOptions
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
